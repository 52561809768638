
import { defineComponent } from 'vue';
import PreLoader from '@/components/PreLoader/PreLoader.vue';
// import UiOverlay from '@/components/form/UiOverlay.vue';
import UiErrors from '@/components/form/UiErrors.vue';
import InventoryService from '@/services/InventoryService';
import UiTextField from '@/components/form/UiTextField.vue';
import MediaManager from '@/components/modals/MediaManager.vue';
import UiTextEditor from '@/components/form/UiTextEditor.vue';
import UiButton from '@/components/form/UiButton.vue';
import UiSearch from '@/components/form/UiSearch.vue';
import Pagination from '@/components/pagination/Pagination.vue';
import RolePermissions from '@/mixins/RolePermissions';

export default defineComponent({
  name: 'CompanyEdit',
  mixins: [RolePermissions],
  data: () => ({
    isLoaded: false,
    isProcessing: false,
    errors: {} as any,
    company: {
      description: '',
      details: {
        address: {},
        company_contact: [],
        contacts: [],
        images: [],
      },
      short_code: '',
      title: '',
    } as any,
    drugs: [],
    drugsTotal: 0,
    drugPage: 1,
    drugPages: 0,
    searchTimeoutId: 0,
    drugSearchInput: '',
    // confirmationModal: false,
  }),
  computed: {
    id(): any {
      return this.$route.params.id;
    },
    baseApiUrl() {
      return process.env.VUE_APP_BASE_API_URL;
    },
    currentItems(): number {
      const currentTotal = 10 * this.drugPage;
      return currentTotal >= this.drugsTotal ? this.drugsTotal : currentTotal;
    },
    canEdit(): boolean {
      return this.hasRole('super-admin') || this.hasRole('admin');
    },
  },
  components: {
    PreLoader,
    // UiOverlay,
    UiErrors,
    UiTextField,
    MediaManager,
    UiTextEditor,
    UiButton,
    UiSearch,
    Pagination,
  },
  watch: {
    async drugSearchInput(val) {
      try {
        this.isProcessing = true;

        this.drugPage = 1;

        window.clearTimeout(this.searchTimeoutId);

        this.searchTimeoutId = window.setTimeout(async () => {
          const params = {
            title: val,
            producer_id: this.company.id,
            published: true,
            order_by: 'title:asc,code:asc',
            take: 10,
          };

          const drugs = await InventoryService.drugSearch(params);

          this.drugs = drugs.data.map((el: any) => this.transformDrugForEdit(el));
          this.drugsTotal = drugs.total;
          this.drugPages = drugs.last_page;

          this.isProcessing = false;
        }, 300);
      } catch (error) {
        if (error) this.isProcessing = false;
      }
    },
  },
  methods: {
    transformCompanyForEdit(model: any) {
      return {
        code: model.short_code,
        description: model.description,
        details: {
          address: {
            country: model.details.address.country,
            region_postcode: model.details.address.region_postcode,
            city: model.details.address.city,
            street_appartment: model.details.address.street_appartment,
          },
          company_contact: {
            cvrnr: model.details.company_contact.cvrnr,
            email: model.details.company_contact.email,
            telephone: model.details.company_contact.telephone,
            website: model.details.company_contact.website,
          },
          contacts: model.details.contacts?.length
            ? model.details.contacts.map((el: any) => ({
              topic: el.topic,
              direct: el.direct,
              email: el.email,
              full_name: el.full_name,
              mobile: el.mobile,
            }))
            : [],
          images: model.details.images.map((el: any) => ({
            default: el.default,
            description: el.description,
            link: el.link,
            path: el.path,
          })),
        },
        id: model.id,
        short_code: model.short_code,
        slug: model.slug,
        title: model.title,
      };
    },
    transformCompanyForSave(model: any) {
      return {
        code: model.code,
        description: model.description,
        details: {
          address: {
            country: model.details.address.country,
            region_postcode: model.details.address.region_postcode,
            city: model.details.address.city,
            street_appartment: model.details.address.street_appartment,
          },
          company_contact: {
            cvrnr: model.details.company_contact.cvrnr,
            email: model.details.company_contact.email,
            telephone: model.details.company_contact.telephone,
            website: model.details.company_contact.website,
          },
          contacts: model.details.contacts.map((el: any) => ({
            topic: el.topic,
            direct: el.direct,
            email: el.email,
            full_name: el.full_name,
            mobile: el.mobile,
          })),
          images: model.details.images.map((el: any) => ({
            path: el.path,
            description: el.description,
            link: el.link?.uri
              ? {
                uri: el.link.uri,
                text: '',
              }
              : '',
            default: el.default,
          })),
        },
        short_code: model.short_code,
        slug: model.slug,
        title: model.title,
      };
    },
    transformDrugForEdit(model: any) {
      return {
        id: model.id,
        slug: model.slug,
        title: model.title,
        dosage_form_text: model.details.dosage_form.text,
        strength_text: model.details.strength.text ? `(${model.details.strength.text})` : '',
        description: model.entity_categories
          ?.filter((el: any) => el.category_type_code === 'activeingredient')
          ?.map((el: any) => el.category_title)
          ?.join(', '),
        promoted: model.promoted,
      };
    },
    addContact() {
      this.company.details.contacts.push({
        topic: '',
        direct: '',
        email: '',
        full_name: '',
        mobile: '',
      });
    },
    removeContact(i: number) {
      this.$nextTick(() => this.company.details.contacts.splice(i, 1));
    },
    isValidPhone(value: string) {
      return /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g.test(value);
    },
    isValidEmail(value: string) {
      return String(value)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
    },
    isValidUrl(value: string) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(value);
    },
    async updateCompany() {
      try {
        this.isProcessing = true;
        this.errors = {};

        if (!this.company.title) {
          this.errors.title = ['Title is required'];
        }

        if (!this.company.short_code) {
          this.errors.short_code = ['Short name is required'];
        }

        // address

        if (!this.company.details.address.country) {
          this.errors.country = ['Country is required'];
        }

        if (!this.company.details.address.region_postcode) {
          this.errors.region_postcode = ['Region postcode is required'];
        }

        if (!this.company.details.address.city) {
          this.errors.city = ['City is required'];
        }

        if (!this.company.details.address.street_appartment) {
          this.errors.street_appartment = ['Street appartment is required'];
        }

        // company contact

        if (!this.company.details.company_contact.cvrnr) {
          this.errors.cvrnr = ['CVR is required'];
        }

        if (!this.company.details.company_contact.email) {
          this.errors.email = ['Email is required'];
        }

        if (!this.isValidEmail(this.company.details.company_contact.email)) {
          this.errors.email = [
            ...(this.errors.email || []),
            ...['Email is not valid'],
          ];
        }

        if (!this.company.details.company_contact.telephone) {
          this.errors.telephone = ['Telephone is required'];
        }

        if (!this.isValidPhone(this.company.details.company_contact.telephone)) {
          this.errors.telephone = [
            ...(this.errors.telephone || []),
            ...['Telephone is not valid'],
          ];
        }

        if (!this.company.details.company_contact.website) {
          this.errors.website = ['Website is required'];
        }

        if (!this.isValidUrl(this.company.details.company_contact.website)) {
          this.errors.website = [
            ...(this.errors.website || []),
            ...['Website is not valid'],
          ];
        }

        // contacts

        this.company.details.contacts.forEach((contact: any, i: any) => {
          if (!contact.topic) {
            this.errors[`topic_${i}`] = ['Topic is required'];
          }

          if (!contact.direct) {
            this.errors[`direct_${i}`] = ['Direct is required'];
          }

          if (!contact.email) {
            this.errors[`email_${i}`] = ['Email is required'];
          }

          if (!this.isValidEmail(contact.email)) {
            this.errors[`email_${i}`] = [
              ...(this.errors[`email_${i}`] || []),
              ...['Email is not valid'],
            ];
          }

          if (!contact.full_name) {
            this.errors[`full_name_${i}`] = ['Full name is required'];
          }

          if (!contact.mobile) {
            this.errors[`mobile_${i}`] = ['Mobile is required'];
          }

          if (!this.isValidPhone(contact.mobile)) {
            this.errors[`mobile_${i}`] = [
              ...(this.errors[`mobile_${i}`] || []),
              ...['Mobile is not valid'],
            ];
          }
        });

        if (Object.keys(this.errors).length) {
          this.isProcessing = false;
          this.$toast.show('Please enter correct data', 3000);
          return;
        }

        if (!this.hasRole('super-admin') && !this.hasRole('admin')) {
          const specs = this.transformCompanyForSave(this.company);

          await InventoryService.producerLimitedUpdate(this.company.id, specs);

          this.$router.push({
            name: 'CompanyShow',
            params: { id: this.id },
          });

          return;
        }

        const specs = this.transformCompanyForSave(this.company);

        await InventoryService.producerUpdate(this.company.id, specs);

        this.$router.push({
          name: 'CompanyShow',
          params: { id: this.id },
        });

        this.isProcessing = false;
      } catch (error: any) {
        this.isProcessing = false;

        if (
          error?.response?.status === 422
          || error?.response?.status === 404
          || error?.response?.status === 409
        ) {
          this.$toast.show(error.response.data?.error?.message, 3000);
        } else {
          this.$toast.show('Server error', 3000);
        }
      }
    },
    // async deleteCompany() {
    //   try {
    //     this.isProcessing = true;

    //     await InventoryService.producerDelete(this.id);

    //     this.$router.push({ name: 'Home' });

    //     this.isProcessing = false;
    //   } catch (error) {
    //     if (error) this.isProcessing = false;
    //   }
    // },
    async searchDrugs() {
      const params = {
        title: this.drugSearchInput,
        producer_id: this.company.id,
        published: true,
        order_by: 'title:asc',
        page: this.drugPage,
        take: 10,
      };

      const drugs = await InventoryService.drugSearch(params);

      this.drugs = drugs.data.map((el: any) => this.transformDrugForEdit(el));
      this.drugsTotal = drugs.total;
      this.drugPages = drugs.last_page;

      window.scroll({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
  async mounted() {
    if (!this.id) {
      this.isLoaded = true;
      return;
    }

    try {
      const company = await InventoryService.producer(this.id);

      if (
        !this.hasRole('super-admin')
        && !this.hasRole('admin')
        && !this.isOwner(company.short_code)
      ) {
        this.$router.push({ name: '404' });
        return;
      }

      this.company = this.transformCompanyForEdit(company);

      await this.searchDrugs();

      this.isLoaded = true;
    } catch (error: any) {
      if (error?.response?.status === 404) {
        this.$router.push({ name: '404' });
        return;
      }

      if (error?.response?.status === 422 || error?.response?.status === 409) {
        this.$toast.show(error.response.data?.error?.message, 3000);
        return;
      }

      this.$toast.show('Server error', 3000);
    }
  },
});
